import React from 'react';
import Layout from 'components/layout';
import SeoComponent from 'components/seo';


const TopicsLanding = ({ location, children, ...rest }) => {
  return (
    <Layout location={location}>
        <SeoComponent title={rest.title} description={rest.description} keywords={rest.keywords} />
        {children}
    </Layout>
  );
};



export default TopicsLanding;
