import React from 'react';
import { Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { useStrapiTopicData, useStrapiExploreTopicsPageData } from 'hooks'
import TopicsLanding from 'templates/topics-landing';
import { Card, Hero, Breadcrumb } from "components/ui";


// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const ExploreTopics = ({location}) => {
    const { allStrapiTopic } = useStrapiTopicData();
    const { strapiExploreTopic } = useStrapiExploreTopicsPageData()

    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }
    const seo = {
        metaTitle: strapiExploreTopic?.title,
        metaDescription: strapiExploreTopic?.description,
        metaKeywords: strapiExploreTopic?.keywords
      }

    return (
        <TopicsLanding location={location} title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords}>
            <div className="margin-bottom-5">
                <Hero
                img={getSrc(strapiExploreTopic?.hero_image?.background_image?.localFile)}
                color="#f5f5f5"
                >
                    <Breadcrumb pathname={thisLocationPathname} />
                    <h1 name="main-content" id="main-content" className="usa-hero__heading">{strapiExploreTopic?.hero_image?.headline}</h1>
                    {strapiExploreTopic?.hero_image?.description &&
                        <div
                        dangerouslySetInnerHTML={{
                        __html: strapiExploreTopic?.hero_image?.description?.data?.childMarkdownRemark?.html,
                        }}
                        />
                    }
                </Hero>
            </div>
            <div className="grid-container padding-bottom-5">
                <div className="usa-card-group">
                    {allStrapiTopic &&
                        (allStrapiTopic.nodes.length > 0 ? allStrapiTopic.nodes.map(topic => {
                        return (
                                <Link to={`/topics/${topic.slug}`} className="desktop:grid-col-4 tablet:grid-col-6 mobile:grid-col-12 margin-bottom-2 text-no-underline topics-card" key={`explore-topics-${topic.id}`}>
                                    <Card grid="width-full height-full">
                                        <Card.Header>{topic.card_title && <h2 className="usa-card__heading green">{topic.card_title.substring(0,48) ?? topic.title.substring(0,48)}</h2>}</Card.Header>
                                            <Card.Media>
                                                {topic.thumbnail &&
                                                    <div className="usa-card__img">
                                                        <GatsbyImage
                                                        image={getImage(topic.thumbnail?.localFile)}
                                                        alt={`Navigate to ${topic.thumbnail?.alternativeText} topic page`}
                                                        />
                                                    </div>
                                                }
                                            </Card.Media>
                                    </Card>
                                </Link>
                        )
                    }) : <h2>{allStrapiTopic.nodes.length} entries found</h2>)}
                </div>
            </div>
        </TopicsLanding>
    )};

export default ExploreTopics;
